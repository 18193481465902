import React from 'react';

import { Box, Show } from '@core';
import { Card, Divider, SectionContainerSC as SectionContainer } from '@components';

import { CompatibleDevicesDesktop, CompatibleDevicesMobile } from './layouts';

const CompatibleDevices = ({
  title,
  description,
  cards,
  mobileCards,
  mobileCard,
  desktopCard,
  showDivider,
  sectionProps,
}) => (
  <SectionContainer
    data-section-id="CompatibleDevices"
    title={title}
    description={description}
    {...sectionProps}
  >
    <Show.Desktop>
      <CompatibleDevicesMobile cards={mobileCards} cardComponent={mobileCard || Card.IconLink} />
    </Show.Desktop>

    <Show.WidescreenAndUp>
      <CompatibleDevicesDesktop cards={cards} cardComponent={desktopCard || Card.Device} />
      {showDivider && (
        <Box position="relative" px={40} top={-2} zIndex={-1}>
          <Divider m={0} backgroundColor="#ECEDED" />
        </Box>
      )}
    </Show.WidescreenAndUp>
  </SectionContainer>
);

export default CompatibleDevices;
