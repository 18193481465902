import React from 'react';

import { Box, Grid } from '@core';

const CompatibleDevicesDesktop = ({ cards, cardComponent: Card }) => (
  <Grid.Container px={40}>
    <Grid.Row flexWrap="nowrap" justifyContent="space-between">
      {cards.map(({ image, title, button, ...rest }) => (
        <Box key={title}>
          <Card image={image} title={title} button={button} {...rest} />
        </Box>
      ))}
    </Grid.Row>
  </Grid.Container>
);

export default CompatibleDevicesDesktop;
