import React from 'react';

import { Grid } from '@core';

const CompatibleDevicesMobile = ({ cards, cardComponent: Card }) => (
  <Grid.Container px={{ _: 16, md: 40 }}>
    <Grid.Row>
      {cards.map(({ image, title, link, ...rest }) => (
        <Grid.Col size={{ _: 6, md: 3 }} display="flex" justifyContent="center" mb={40} key={title}>
          <Card image={image} title={title} link={link} variant="secondary" {...rest} />
        </Grid.Col>
      ))}
    </Grid.Row>
  </Grid.Container>
);

export default CompatibleDevicesMobile;
